import React, { createContext, useState } from 'react';
import { SpecialContributionDetailsDto } from 'api/accounting';
import { DocumentDto } from 'api/public';

export const SpecialContributionCreationContext = createContext<{
  specialContribution: SpecialContributionDetailsDto | undefined,
  loading: boolean,
  dirty: boolean,
  documents: DocumentDto[],
  setDocuments: React.Dispatch<React.SetStateAction<DocumentDto[]>>,
  documentModalVisible: boolean,
  setDocumentModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSpecialContribution: React.Dispatch<React.SetStateAction<SpecialContributionDetailsDto>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setDirty: React.Dispatch<React.SetStateAction<boolean>>,
}>(undefined);

const SpecialContributionCreationContextProvider = ({ children }) => {
  const [specialContribution, setSpecialContribution] = useState<SpecialContributionDetailsDto | undefined>(undefined);
  const [documents, setDocuments] = useState<DocumentDto[]>([]);
  const [documentModalVisible, setDocumentModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);


  return (
    <SpecialContributionCreationContext.Provider value={{
      specialContribution,
      loading,
      dirty,
      setSpecialContribution,
      setLoading,
      setDirty,
      documents,
      setDocuments,
      documentModalVisible,
      setDocumentModalVisible,
    }}
    >
      {children}
    </SpecialContributionCreationContext.Provider>
  );
};

export default SpecialContributionCreationContextProvider;
