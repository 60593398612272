import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import useWatermarkOptions from 'services/useWatermarkOptions';
import { isNil } from 'lodash';
import { getErrorCodeFromURI } from 'lib/ErrorCodesUtils';
import { translations } from '../../../translations';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import usePropertyOptionsHrIdVersion from '../../../../../services/usePropertyOptionsHrIdVersion';
import { showNotification } from '../../../../../lib/Notification';
import { AuthContext } from '../../../../../contexts/AuthContext';
import {
  SpecialContributionControllerApi, SpecialContributionDetailsDto, SpecialContributionDto,
  SpecialContributionDetailsDtoStateEnum,
} from '../../../../../api/accounting';
import { SpecialContributionValidationStates } from './model/interfacesSpecialContribution';
import './Section1.scss';

export const useSection1 = (
  setDirty: (dirty: boolean) => void,
  setLoading: (value: boolean) => void,
  specialContributionDetails: SpecialContributionDetailsDto | undefined,
  setSpecialContributionDetails: React.Dispatch<React.SetStateAction<SpecialContributionDetailsDto>>,
) => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const { goBack } = useContext(OverlayContext);
  const api = new SpecialContributionControllerApi(apiConfiguration('accounting'));
  const history = useHistory();
  const { specialContributionId } = useParams<{ specialContributionId: string }>();
  const { checkPropertyValidity } = useCheckPropertyValidity();
  const { watermarkOptions, defaultWatermarkId } = useWatermarkOptions();
  const [validationStates, setValidationStates] = useState<SpecialContributionValidationStates>({});

  const {
    managementCompanyEmployeesOptions,
    propertyOptions,
    searchForProperty,
    onLoadProperty,
  } = usePropertyOptionsHrIdVersion();

  const isSectionDisabled = specialContributionDetails?.state !== SpecialContributionDetailsDtoStateEnum.DRAFT && !isNil(specialContributionId);


  useEffect(() => {
    if (defaultWatermarkId) {
      setSpecialContributionDetails({
        ...specialContributionDetails,
        watermarkId: defaultWatermarkId,
      });
    }
  }, [defaultWatermarkId]);


  const save = (isSuccessNotificationRequired: boolean = true) => {
    setLoading(true);
    onClearValidationStates();
    if (specialContributionId) {
      return api.updateSpecialContributionUsingPATCH({
        dto: {
          watermarkId: specialContributionDetails?.watermarkId ?? null,
          signingPersonId: specialContributionDetails?.signingPersonId ?? null,
        },
        id: specialContributionDetails!.id!,
      })
        .then((response: SpecialContributionDto) => {
          setDirty(false);

          setSpecialContributionDetails((prev: SpecialContributionDetailsDto) => ({
            ...prev,
            watermarkId: response.watermarkId,
            signingPersonId: response.signingPersonId,
          }));

          if (isSuccessNotificationRequired) {
            showNotification({
              key: 'saveSpecialContributionSuccess',
              message: tl(translations.specialContribution.saveSpecialContributionSuccess),
              type: 'success',
            });
          }
        })
        .catch((err) => {
          onSetValidationErrors(err);
          showNotification({
            key: 'saveSpecialContributionError',
            message: tl(translations.specialContribution.saveSpecialContributionError),
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    }
    return api.createSpecialContributionUsingPOST({
      creationData: specialContributionDetails,
    })
      .then((response: SpecialContributionDto) => {
        history.replace(`/special-contribution/edit/${response.id}`, { openSectionIndex: 1 });
      })
      .catch((err) => {
        onSetValidationErrors(err);
        showNotification({
          key: 'saveSpecialContributionError',
          message: tl(translations.specialContribution.saveSpecialContributionError),
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const onSetValidationErrors = (err: any) => {
    if (err.response?.status === 400) {
      err.response.json()
        .then((errorBody) => {
          const localvalidationStates: SpecialContributionValidationStates = errorBody.validationIssues?.reduce((acc, validationIssue) => {
            const [_, field] = validationIssue.field.split('.');
            const errorType = getErrorCodeFromURI(validationIssue.type);
            acc[field as keyof SpecialContributionValidationStates] = errorType;
            return acc;
          }, {});

          setValidationStates(localvalidationStates);
        });
    }
  };

  const onClearValidationStates = () => {
    setValidationStates({});
  };

  const onClearValidationError = (fieldName: keyof SpecialContributionValidationStates) => {
    setValidationStates((prevStates) => {
      const { [fieldName]: _, ...rest } = prevStates;
      return rest;
    });
  };

  const getValidationState = (errorType: string) => {
    if (isNil(errorType)) {
      return null;
    }

    return 'error' as const;
  };

  const getValidationMessage = (errorType: string) => {
    if (isNil(errorType)) {
      return null;
    }

    return tl(translations.specialContribution.validations[errorType]);
  };


  return {
    watermarkOptions,
    onClearValidationError,
    managementCompanyEmployeesOptions,
    propertyOptions,
    searchForProperty,
    onLoadProperty,
    isSectionDisabled,
    validationStates,
    save,
    goBack,
    checkPropertyValidity,
    getValidationState,
    getValidationMessage,
    specialContributionId,
  };
};
