import React, { useContext, useMemo, useState } from 'react';
import { PropertyLegacyControllerApi, FindFilteredPropertiesUsingGETPropertyStatesEnum, FindFilteredPropertiesUsingGETAdministrationTypesEnum, PropertyDisplayDtoPropertyStateEnum, ContactLinkedDto } from 'api/accounting';
import { OptionInterface } from 'elements/Inputs/SmartSearch/SmartSearch';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from '../contexts/LanguageContext';
import { PropertyDisplay } from '../data/property';
import { DropdownOptionElement } from '../elements/CustomElements/DropdownOptionElement/DropdownOptionElement';
import { showNotification } from '../lib/Notification';
import { AuthContext } from '../contexts/AuthContext';
import { isNil } from 'lodash';

/*
  DEPRECATED
  only use it for old flows
 */
export default function usePropertyOptionsHrIdVersion() {
  const { tl } = useContext(LanguageContext);
  const [properties, setProperties] = useState(DEFAULT_DATA<PropertyDisplay[]>([]));
  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));

  const optionObjectFromProperty = (property: PropertyDisplay) => {
    const label = property.name;
    let labelWithState = label;
    if (property.propertyState !== PropertyDisplayDtoPropertyStateEnum.READY) {
      // display the state everytime the property is not ready for accounting
      labelWithState = `${label} - ${tl(translations.elements.propertyOptionElement.stateLabels[property.propertyState])}`;
    }
    let additionalLabelInfo;
    if (property.propertyIdInternal) {
      additionalLabelInfo = `${property.propertyIdInternal} | ${property.administrationType} | ${tl(translations.elements.propertyOptionElement.stateLabels[property.propertyState])}`;
    }
    return {
      value: property.propertyHrId,
      label: <DropdownOptionElement
        label={label}
        additionalLabelInfo={additionalLabelInfo}
      />,
      simpleLabel: label,
    };
  };

  const propertyOptions = useMemo(() => (properties.data ? properties.data.map(optionObjectFromProperty) : []), [properties]);

  // ts-ignore
  const searchForProperty = (filter: string, administrationTypes: FindFilteredPropertiesUsingGETAdministrationTypesEnum[]) => {
    setProperties(state => state.startLoading());
    propertyControllerApi.findFilteredPropertiesUsingGET({
      // @ts-ignore
      propertyStates: [FindFilteredPropertiesUsingGETPropertyStatesEnum.READY],
      // @ts-ignore
      administrationTypes,
      searchString: filter,
      size: 30,
    })
      .then((response: any) => {
        setProperties(state => state.load(response.content));
      })
      .catch((error) => {
        console.error(error);
        setProperties(state => state.failed());
        showNotification({
          key: 'loadPropertiesError',
          message: tl(translations.notifications.propertyListContext.loadError.message),
          description: tl(translations.notifications.propertyListContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onLoadProperty = (hrId: number): void => {
    if (properties.data && properties.data.filter((prp: any) => prp.propertyHrId === hrId).length > 0) return;

    setProperties(state => state.startLoading());
    backend.get(`${endpointUrls.PROPERTY}/display/by-hr-id`, { propertyHrId: hrId })
      .then((response: any) => {
        if (properties.data && properties.data.filter((prp: any) => prp.propertyHrId === hrId).length > 0) return;
        setProperties(state => state.load(state.data ? state.data.concat(response) : response));
      })
      .catch((error) => {
        console.error(error);
        setProperties(state => state.failed());
        showNotification({
          key: 'loadPropertiesError',
          message: tl(translations.notifications.propertyEditingContext.loadError.message),
          description: tl(translations.notifications.propertyEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const getProperty = (hrId: number) => {
    const property = properties.data ? properties.data.filter((prp: any) => prp.propertyHrId === hrId) : [];
    if (property.length > 0) return property[0];
    return null;
  };


  const managementCompanyEmployeesOptions = useMemo(() => {
    const contactOptions: OptionInterface[] = [];
    properties.data.forEach((property: PropertyDisplay) => {
      property!.managementCompany!.linkedByContacts!.forEach((linkedContact: ContactLinkedDto) => {
        if (contactOptions.filter((contact: OptionInterface) => contact.value === linkedContact.contactId).length === 0
          && (!isNil(linkedContact.contactFirstName) || !isNil(linkedContact.contactLastName))) {
          contactOptions.push({
            value: linkedContact.contactId,
            label: `${linkedContact.contactFirstName ?? ''} ${linkedContact.contactLastName ?? ''}`,
          });
        }
      });
    });


    return contactOptions;
  }, [properties.data]);


  return {
    managementCompanyEmployeesOptions,
    propertyOptions,
    searchForProperty,
    onLoadProperty,
    getProperty,
  };
}
